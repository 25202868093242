<template>
  <div class="home">
    <v-layout row wrap>
      <v-flex xs12 md12 lg12 class="mb-5">
        <h1 class="f-alfa primary--text">
          <router-link to="/">Dashboard</router-link> / Flight Status
        </h1>
      </v-flex>
      <div class="pb-5" v-if="$vuetify.breakpoint.smAndDown">
        <v-date-picker v-model="picker" @change="selectDate"></v-date-picker>
      </div>
      <v-flex lg12 class="d-flex">
        <div class="ml-5 mr-5" v-if="$vuetify.breakpoint.mdAndUp">
          <v-date-picker v-model="picker" @change="selectDate"></v-date-picker>
        </div>
        <v-flex lg8 xs12>
          <v-simple-table>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left uppercase primary--text">Flight</th>
                  <!-- <th class="text-left uppercase primary--text">Time</th> -->
                  <th class="text-left uppercase primary--text">Etd</th>
                  <th class="text-left uppercase primary--text">Landing</th>
                  <th class="text-left uppercase primary--text">
                    Seats Filled
                  </th>
                  <th class="text-left uppercase primary--text">Status</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="item in schedules" :key="item._id">
                  <td class="primary--text">{{ item.ac_unique_code }}</td>
                  <!-- <td>{{ item.time }}</td> -->
                  <td>{{ item.etd }}</td>
                  <td>{{ item.landing_time }}</td>
                  <td>{{ item.seats_booked }} / {{ item.seats }}</td>
                  <td
                    :class="
                      getStatus(
                        item.date,
                        item.flight_take_off,
                        item.landing_time
                      ).cls
                    "
                  >
                    {{
                      getStatus(
                        item.date,
                        item.flight_take_off,
                        item.landing_time
                      ).text
                    }}
                  </td>
                  <td>
                    <v-btn @click="modify" small class="primary--text"
                      ><v-icon style="font-size: 10pt"
                        >mdi-pencil</v-icon
                      ></v-btn
                    >
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-flex>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
import { BASE_URL } from "../../config";
import Axios from "axios";
export default {
  data() {
    return {
      picker: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      schedules: [],
    };
  },
  methods: {
    selectDate() {
      this.fetchSchedules();
    },
    getCurrentTimeIn24HourFormatWithoutColon() {
      var now = new Date();
      var hours = now.getHours().toString().padStart(2, "0");
      var minutes = now.getMinutes().toString().padStart(2, "0");
      return hours + minutes;
    },
    getStatus(date, time, landing_time) {
      var givenDate = new Date(date);

      // Get the current date
      var currentDate = new Date();

      // Extract year, month, and day components
      var givenYear = givenDate.getFullYear();
      var givenMonth = givenDate.getMonth();
      var givenDay = givenDate.getDate();

      var currentYear = currentDate.getFullYear();
      var currentMonth = currentDate.getMonth();
      var currentDay = currentDate.getDate();

      // Check conditions
      if (
        givenYear === currentYear &&
        givenMonth === currentMonth &&
        givenDay === currentDay
      ) {
        if (
          this.getCurrentTimeIn24HourFormatWithoutColon() > time &&
          this.getCurrentTimeIn24HourFormatWithoutColon() < landing_time
        ) {
          return {
            text: "In Progress",
            cls: "in-progress",
          };
        }
        if (this.getCurrentTimeIn24HourFormatWithoutColon() < time) {
          return {
            text: "Scheduled",
            cls: "scheduled",
          };
        } else {
          return {
            text: "Completed",
            cls: "completed",
          };
        }
        // alert(time)
      } else if (givenDate < currentDate) {
        return {
            text: "Completed",
            cls: "completed",
          };
      } else {
        return {
            text: "Scheduled",
            cls: "scheduled",
          };
      }
    },
    async fetchSchedules() {
      this.schedules = [];
      this.$store.dispatch("SET_PROGRESS", true);
      let url = BASE_URL + "/schedule?date=" + this.picker;
      let { data } = await Axios.get(url);
      this.schedules = data.schedules;
      this.$store.dispatch("SET_PROGRESS", false);
    },
  },
  mounted() {
    this.fetchSchedules();
  },
};
</script>

<style>
.in-progress {
  color: orange !important;
}
.scheduled {
  color: #ba9d73;
}
.completed {
  color: #afbda1;
}
</style>

